import React, { useState, useEffect, useMemo } from 'react';
import {TableCard} from "../generic/cards";
import {useHistory, useParams} from 'react-router-dom';
import {Col, FormGroup, Input,} from "reactstrap";
import {FaPlus} from "react-icons/fa";
import {ModalConfirmation} from "../generic/modals";
import CustomAdventuresList from "./CustomAdventuresList";
import {RangedDatePicker, TextSearch} from "../generic/fields";
import dayjs from "dayjs";
import PropTypes from 'prop-types';
import {useAdventuresApi} from "../../lib/api/adventures";
import {ElementWithPermissions} from "../common/formElements";
import {ENTITIES, useRoleSystem} from "../../lib/utils/hooks/useRoleSystem";
import i18next, {t} from "i18next";
import { onNewPage, onRoleEntity } from './adventure/helpers';
import {PRODUCT_TYPES} from "sv-common/constants/certificates";

const AdventuresList = ({is_school = false, isInline, createTranslatedBreadcrumbsAndTitle, is_camp = false, is_charter = false}) => {
    const now = dayjs();
    const {type} = useParams();
    let history = useHistory();
    const {findAndCountEntries, archiveAdventure, createEntry} = useAdventuresApi();
    let entity = useMemo(() => onRoleEntity(is_school, is_camp, is_charter, type), [is_school, type, is_camp, is_charter]);
    let permissions = useRoleSystem(entity);
    const [isModalOpen, setModalOpen] = useState();
    const toggleModal = () => setModalOpen(isOpen => !isOpen);

    const [sortField, setSortField] = useState('date');

    const [selectedAdventure, setSelectedAdventure] = useState(undefined);
    const confirmArchiveAdventure = () => archiveAdventure(selectedAdventure.id).then(() => toggleModal())
    const deleteAdventure = (adventure) => {
        setSelectedAdventure(() => adventure || {});
        toggleModal();
    }

    const editAdventure = (adventure) => {
      onNewPage(adventure.url, is_school, is_camp, is_charter, true, history, type, true);
    }

    const [showType, setShowType] = useState('current');
    const [countryFilter, setCountryFilter] = useState(undefined);
    const [nameFilter, setNameFilter] = useState(undefined);
    const [daysFilter, setDaysFilter] = useState({
        from: now.toDate(),
        to: now.add(2, 'years').toDate()
    })
    const [typeForRule, setTypeForRule] = useState('');

    const cloneEntry = (adventure) => {
        const {arrivals_ids, name, url, next_arrival, last_arrival, ...emptyAdventure} = adventure;
        createEntry({...emptyAdventure, name: name + '_copy', url: url + '_copy'}).then((res) => {editAdventure(res)})
    }

    const filters = {
        type: type || undefined,
        is_archived: false,
        country_contains: countryFilter,
        name_contains: nameFilter,
        is_school,
        is_camp,
        is_charter,
    }

    const currentFilters = {
        ...filters,
    }

    currentFilters["_where[_or][0][last_arrival.start_date_gte]"] = daysFilter.from;
    currentFilters["_where[_or][0][last_arrival.start_date_lte]"] = daysFilter.to;

    const pastFilters = {
        ...filters,
        last_arrival_null: false,
        'last_arrival.start_date_lte': daysFilter.from,
        'last_arrival.end_date_gte': daysFilter.to
    }

    const archivedFilters = {
        ...filters,
        is_archived: true
    }

    const arrivalFreeFilters = {
        ...filters,
    }

    const {language} = i18next;

    useEffect(() => {
        if (showType === 'current')
            setDaysFilter(() => ({
                from: now.toDate(),
                to: now.add(2, 'years').toDate()
            }))
        if (showType === 'past')
            setDaysFilter(() => ({
                from: now.toDate(),
                to: now.subtract(2, 'years').toDate()
            }))
    }, [showType])

    useEffect(() => {
        const mapper = {
                type: {ru: type === 'world' ? t('adventures.adventuresList.world') : 'Россия', en: type}
        };

        createTranslatedBreadcrumbsAndTitle && createTranslatedBreadcrumbsAndTitle(mapper, mapper);

        if (!is_camp && !is_charter && !is_school) {
            setTypeForRule(PRODUCT_TYPES.ARRIVALS);
        } else if (is_school) {
            setTypeForRule(PRODUCT_TYPES.PRACTICES);
        }
    }, [type, language])

    const isEnabledInputForRussia = entity === ENTITIES.ADVENTURES_RUSSIA || entity === ENTITIES.CAMPS_RUSSIA;

    return <TableCard isInline={isInline}>
        <ModalConfirmation onClose={toggleModal} onConfirm={confirmArchiveAdventure} isOpen={isModalOpen}
                           title={t('adventures.adventuresList.deleteTravel')} text={t('adventures.adventuresList.areYouSure')}
                           confirmButtonText={t('adventures.adventuresList.sure')}/>
        <div className={'row mb-3'}>
            <Col xs={12} md={1}>
                <FormGroup>
                    <h4 className={'display-4'}>
                        <ElementWithPermissions disabled={!permissions.edit}>
                            <button className={'btn btn-rounded btn-primary btn-sm ml-xl-4'} onClick={editAdventure}>
                                <FaPlus/>
                            </button>
                        </ElementWithPermissions>

                    </h4>
                </FormGroup>
            </Col>
            <Col sx={12} md={3} xl={2}>
                <FormGroup>
                    <Input type={'select'} value={showType} onChange={e => setShowType(() => e.target.value)}>
                        <option value={'current'}>{t('adventures.adventuresList.current')}</option>
                        <option value={'past'}>{t('adventures.adventuresList.past')}</option>
                        <option value={'archived'}>{t('adventures.adventuresList.archive')}</option>
                        <option value={'arrivalFree'}>{t('adventures.adventuresList.all')}</option>
                    </Input>
                </FormGroup>
            </Col>
           {!isEnabledInputForRussia && <Col sm={6} md={4} xl={2}>
              <FormGroup>
                  <TextSearch placeholder={t('adventures.adventuresList.russia')} value={countryFilter || ''}
                              onSearch={ e => setCountryFilter(() => e)}/>
              </FormGroup>
            </Col>}
            <Col sm={6} md={4} xl={2}>
                <FormGroup>
                    <TextSearch placeholder={t('adventures.adventuresList.gastroRegata')} value={nameFilter || ''}
                                onSearch={ e => setNameFilter(() => e)}/>
                </FormGroup>
            </Col>
            <Col sm={12} md={6} xl={4}>
                <FormGroup>
                    <RangedDatePicker daysFilter={daysFilter} setDaysFilter={setDaysFilter} size={'lg'}/>
                </FormGroup>
            </Col>
            {showType !== 'arrivalFree' && <Col sm={12} md={6} xl={1}>
                <FormGroup>
                    <Input type={'select'} value={sortField} onChange={e => setSortField(() => e.target.value)}>
                        <option value={'date'}>{t('adventures.adventuresList.date')}</option>
                        <option value={'load'}>{t('adventures.adventuresList.load')}</option>
                    </Input>
                </FormGroup>
            </Col>}
        </div>

        {showType === 'current' &&
            <CustomAdventuresList filters={currentFilters} cloneEntry={cloneEntry} name={'current_adventures'}
                                  findAndCountEntries={findAndCountEntries} deleteAdventure={deleteAdventure}
                                  editEntry={editAdventure} type={showType} permissions={permissions}
                                  sortField={'next_arrival.' + (sortField === 'date' ? 'start_date' : 'booked_to_total')}
                                  sortDir={sortField === 'date' ? 'ASC' : 'DESC'} typeForRule={typeForRule}/>
        }
        {showType === 'past' &&
        <CustomAdventuresList filters={pastFilters} cloneEntry={cloneEntry} name={'past_adventures'}
                              findAndCountEntries={findAndCountEntries} deleteAdventure={deleteAdventure}
                              editEntry={editAdventure} type={showType} permissions={permissions}
                              sortField={(sortField === 'date' ? 'last_arrival.start_date' : 'average_load')}
                              sortDir={'DESC'} typeForRule={typeForRule}/>
        }
        {showType === 'archived' &&
        <CustomAdventuresList filters={archivedFilters} cloneEntry={cloneEntry} name={'archived_adventures'}
                              findAndCountEntries={findAndCountEntries} deleteAdventure={deleteAdventure}
                              editEntry={editAdventure} type={showType} permissions={permissions}
                              sortField={(sortField === 'last_arrival.date' ? 'start_date' : 'average_load')}
                              sortDir={'DESC'} typeForRule={typeForRule}/>
        }
        {showType === 'arrivalFree' &&
        <CustomAdventuresList filters={arrivalFreeFilters} cloneEntry={cloneEntry} name={'arrival_free_adventures'}
                              findAndCountEntries={findAndCountEntries} deleteAdventure={deleteAdventure}
                              editEntry={editAdventure} type={showType} permissions={permissions} typeForRule={typeForRule}/>
        }


    </TableCard>
}

export default AdventuresList;

AdventuresList.propTypes = {
    is_school: PropTypes.bool,
    isInline: PropTypes.bool,
    is_camp: PropTypes.bool,
    is_charter: PropTypes.bool,
}