import React from 'react'
import {FaPlus, FaWarehouse, FaChild} from "react-icons/fa";
import {FiUsers} from 'react-icons/fi'
import {ImBooks} from 'react-icons/im'
import { GiEarthAfricaEurope, GiSailboat } from 'react-icons/gi';
import {IoMdSchool} from 'react-icons/io'
import {BiLinkAlt} from "react-icons/all";
import {frk} from "sv-common/constants/frontRoles";

export const routes = [
    {
        title: "routes.bases",
        to: "/bases",
        icon: <FaWarehouse/>,
        access: ["Super-admin", "Administrator", 'Sport', 'Call-center'],
        isOpenConstantly: true,
        linkClickable: true,
        children: [
            // {title: "Календарь", to: "/bases/calendar", access: ["Super-admin", "Administrator", 'Sport]},
        ],
        linkClickableAccessKey: frk["SIDEBAR-BASES"],
    },
    {
        title: "routes.adventures",
        to: "/adventures",
        icon: <GiEarthAfricaEurope/>,
        access: ["Super-admin", "Administrator", 'Sport', 'Call-center'],
        linkClickable: false,
        children: [
            {
                title: "routes.russia",
                to: "/adventures/russia",
                access: ["Super-admin", "Administrator", 'Sport', 'Call-center'],
                hideInCountries: ['EN'],
                extraLinks: [
                    {
                        link: <div className={'btn btn-light btn-rounded p-0 ml-2'} title={'Создать приключение'}>
                            <FaPlus/></div>,
                        to: '/adventures/russia/edit',
                    }
                ],
            },
            {
                title: "routes.world",
                to: "/adventures/world",
                access: ["Super-admin", "Administrator", 'Sport', 'Call-center'],
                extraLinks: [
                    {
                        link: <div className={'btn btn-light btn-rounded p-0 ml-2'} title={'Создать приключение'}>
                            <FaPlus/></div>,
                        to: '/adventures/world/edit',
                    }
                ]
            },
        ],
        accessKey: frk["SIDEBAR-ADVENTURES"]
    },
    {
        title: "routes.camps",
        to: "/camps",
        icon: <GiEarthAfricaEurope/>,
        access: ["Super-admin", "Administrator", 'Sport', 'Call-center'],
        linkClickable: false,
        hideInCountries: ['EN'],
        children: [
            {
                title: "routes.russia",
                to: "/camps/russia",
                access: ["Super-admin", "Administrator", 'Sport', 'Call-center'],
                hideInCountries: ['EN'],
                extraLinks: [
                    {
                        link: <div className={'btn btn-light btn-rounded p-0 ml-2'}>
                            <FaPlus/></div>,
                        to: '/camps/russia/edit',
                    }
                ],
                accessKey: frk["SIDEBAR-CAMPS-RUSSIA"]
            },
            {
                title: "routes.world",
                to: "/camps/world",
                access: ["Super-admin", "Administrator", 'Sport', 'Call-center'],
                extraLinks: [
                    {
                        link: <div className={'btn btn-light btn-rounded p-0 ml-2'}>
                            <FaPlus/></div>,
                        to: '/camps/world/edit',
                    }
                ],
                accessKey: frk["SIDEBAR-CAMPS-WORLD"]
            },
        ]
    },
    {
        title: "routes.charters",
        to: "/charters/world",
        icon: <GiSailboat />,
        access: ["Super-admin", "Administrator", 'Sport', 'Call-center'],
        linkClickable: false,
        hideInCountries: ['EN'],
        children: [
            {
                title: "routes.world",
                to: "/charters/world",
                access: ["Super-admin", "Administrator", 'Sport', 'Call-center'],
                extraLinks: [
                    {
                        link: <div className={'btn btn-light btn-rounded p-0 ml-2'}>
                            <FaPlus/></div>,
                        to: '/charters/world/edit',
                    }
                ]
            },
        ],
        accessKey: frk["SIDEBAR-CHARTER"]
    },
    {
        title: "routes.school",
        to: "/school",
        icon: <IoMdSchool/>,
        access: ["Super-admin", "Administrator", 'Sport', 'Call-center'],
        linkClickable: false,
        children: [
            {
                title: "routes.theory",
                to: "/school/theory",
                hideInCountries: ['EN'],
                access: ["Super-admin", "Administrator", 'Sport', 'Call-center'],
            },
            {
                title: "routes.practices",
                to: "/school/practices",
                hideInCountries: ['EN'],
                access: ["Super-admin", "Administrator", 'Sport', 'Call-center'],
            },
            {
                title: "routes.lectures",
                to: "/school/lectures",
                access: ["Super-admin", "Administrator", 'Sport', 'Call-center'],
            },
        ],
        accessKey: frk["SIDEBAR-SCHOOL"]
    },
    {
        title: "routes.kids_school",
        to: "/kids-school",
        icon: <FaChild/>,
        access: ['Tester'],
        isOpenConstantly: false,
        linkClickable: false,
        children: []
    },
    {
        title: "routes.administration",
        to: "/administration",
        icon: <FiUsers/>,
        access: ["Super-admin", "Administrator", 'Sport', 'Call-center'],
        children: [
            {
                title: "routes.orders",
                to: "/administration/orders",
                access: ["Super-admin", "Administrator", 'Sport', 'Call-center'],
                accessKey: frk["SIDEBAR-MANAGEMENT-ORDERS"]
            },
            {
                hideInCountries: ['EN'],
                title: "routes.instructors",
                to: "/administration/instructors",
                access: ["Super-admin", "Administrator", 'Sport', 'Call-center'],
                accessKey: frk["SIDEBAR-MANAGEMENT-INSTRUCTORS"]
            },
            {
                title: "routes.captains",
                to: "/administration/captains",
                access: ["Super-admin", "Administrator", 'Sport', 'Call-center'],
                hideInCountries: ['EN', 'RU'],
            },
            {
                title: "routes.promocodes",
                to: "/administration/promocodes",
                access: ["Super-admin", "Administrator", 'Sport', 'Call-center', 'Call-center'],
                accessKey: frk["SIDEBAR-MANAGEMENT-PROMOS"]
            },
            {
                title: "routes.certificates",
                to: "/administration/certificates",
                access: ["Super-admin", "Administrator", 'Sport', 'Call-center', 'Call-center'],
                accessKey: frk["SIDEBAR-MANAGEMENT-CERTIFICATES"]
            },
            {
                title: "routes.violations",
                to: "/administration/violations",
                access: ["Super-admin", "Administrator", 'Sport', 'Call-center'],
                accessKey: frk["SIDEBAR-MANAGEMENT-BLACKLIST"]
            },
            {
                title: "routes.feedback",
                to: "/administration/feedback",
                access: ["Super-admin", "Administrator", 'Sport', 'Call-center'],
                accessKey: frk["SIDEBAR-MANAGEMENT-REVIEWS"]
            },
            {
                title: "routes.promocodes_packages",
                to: "/administration/promocodes-packages",
                access: ["Super-admin", "Administrator", 'Sport'],
                accessKey: frk["SIDEBAR-MANAGEMENT-PROMOPACKAGES"]
            },
            {
                title: "routes.exports",
                to: "/administration/exports",
                access: ["Super-admin", "Administrator", 'Sport'],
                accessKey: frk["SIDEBAR-MANAGEMENT-EXPORTS"]
            },
            {
                title: "routes.users",
                to: "/administration/users",
                access: ["Super-admin", "Administrator", 'Sport', 'Call-center'],
                accessKey: frk["SIDEBAR-MANAGEMENT-USERS"]
            },
            {
                title: "routes.links_generator",
                to: "/administration/links-generator",
                access: ["Super-admin", "Administrator", 'Sport', 'Call-center'],
                accessKey: frk["SIDEBAR-MANAGEMENT-LINKSGENERATOR"]
            },
            {
                title: "routes.materials",
                to: "/administration/materials",
                access: ["Super-admin", "Administrator", 'Sport', 'Call-center'],
                accessKey: frk["SIDEBAR-MANAGEMENT-MATERIALS"]
            },
            {
                title: "routes.frontendRoles",
                to: "/administration/frontend-roles",
                access: ["Super-admin"],
            },
        ]
    },
    {
        title: "routes.constructor",
        to: "/constructor",
        icon: <BiLinkAlt/>,
        access: ["Super-admin", "Administrator", 'Sport', 'Call-center'],
        children: [
            {
                title: "routes.projects",
                to: "/constructor/projects",
                access: ["Super-admin", "Administrator", 'Sport', 'Call-center'],
            },
            {
                title: "routes.templates",
                to: "/constructor/templates",
                access: ["Super-admin", "Administrator", 'Sport', 'Call-center'],
            },
            {
                title: "routes.pages",
                to: "/constructor/pages",
                access: ["Super-admin", "Administrator", 'Sport', 'Call-center'],
            },
            {
                title: "routes.letters",
                to: "/constructor/letters",
                access: ["Super-admin", "Administrator", 'Sport', 'Call-center'],
            },
            {
                title: "routes.files",
                to: "/constructor/files",
                access: ["Super-admin", "Administrator", 'Sport', 'Call-center'],
            },
            {
                title: "routes.constructorDirectory",
                to: "/constructor/directory",
                access: ["Super-admin", "Administrator", 'Sport', 'Call-center'],
            },
            {
                title: "routes.corp_clients",
                to: "/constructor/corp-clients",
                access: ["Super-admin", "Administrator", 'Sport'],
                accessKey: frk["SIDEBAR-DIRECTORY-CORPCLIENTS"]
            },
            {
                title: "routes.coordinators",
                to: "/constructor/coordinators",
                access: ["Super-admin", "Administrator", 'Sport'],
                accessKey: frk["SIDEBAR-DIRECTORY-COORDINATORS"]
            },
            {
                title: "routes.base_addresses",
                to: "/constructor/base_addresses",
                access: ["Super-admin", "Administrator", 'Sport'],
                accessKey: frk["SIDEBAR-DIRECTORY-BASEADDRESSES"]
            },
            {
                title: "routes.corp_users",
                to: "/constructor/users",
                access: ["Super-admin", "Administrator", 'Sport'],
                accessKey: frk["SIDEBAR-DIRECTORY-CORPUSERS"]
            },
            {
                title: "routes.areas",
                to: "/constructor/areas",
                access: ["Super-admin", "Administrator", 'Sport'],
            },
        ],
        accessKey: frk["SIDEBAR-CONSTRUCTOR"]
    },
    {
        title: "routes.directories",
        to: "/directories",
        icon: <ImBooks/>,
        access: ["Super-admin", "Administrator", 'Sport'],
        children: [
            {
                title: "routes.subscriptions",
                to: "/directories/subscriptions",
                access: ["Super-admin", "Administrator", 'Sport'],
                accessKey: frk["SIDEBAR-DIRECTORY-SUBSCRIPTIONS"]
            },
            {
                title: "routes.difficulties",
                to: "/directories/difficulties",
                access: ["Super-admin", "Administrator", 'Sport'],
                accessKey: frk["SIDEBAR-DIRECTORY-DIFFICULTYSCALES"]
            },
            {
                title: "routes.user_fields",
                to: "/directories/user_fields",
                access: ["Super-admin", "Administrator", 'Sport'],
                accessKey: frk["SIDEBAR-DIRECTORY-CUSTOMFIELDS"]
            },
            {
                title: "routes.user_fields_templates",
                to: "/directories/user_fields_templates",
                access: ["Super-admin", "Administrator", 'Sport'],
                accessKey: frk["SIDEBAR-DIRECTORY-TEMPLATESCUSTOMFIELDS"]
            },
            {
                title: "routes.transaction_status",
                to: "/directories/transaction-status",
                access: ["Super-admin", "Administrator", 'Sport'],
                accessKey: frk["SIDEBAR-DIRECTORY-TRANSSTATUS"]
            },
            // TODO вернуть эту фичу
            // {
            //     title: "Опции приключений",
            //     to: "/directories/options",
            //     access: ["Super-admin", "Administrator", 'Sport],
            // },
            {
                title: "routes.currencies",
                to: "/directories/currencies",
                access: ["Super-admin", "Administrator", 'Sport'],
                hideInCountries: ['EN', 'RU'],
            },
            {
                title: "routes.boats_classes",
                to: "/directories/boats_classes",
                access: ["Super-admin", "Administrator", 'Sport'],
                accessKey: frk["SIDEBAR-DIRECTORY-BOATCLASSES"]
            },
            {
                hideInCountries: ['EN'],
                title: "routes.shirt_sizes",
                to: "/directories/shirt_sizes",
                access: ["Super-admin", "Administrator", 'Sport'],
                accessKey: frk["SIDEBAR-DIRECTORY-TSHORTSIZES"]
            },
            {
                title: "routes.public_contracts",
                to: "/directories/public_contracts",
                access: ["Super-admin", "Administrator", 'Sport'],
                accessKey: frk["SIDEBAR-DIRECTORY-OFFERS"]
            },
            {
                title: "routes.extra_materials",
                to: "/directories/extra_materials",
                access: ["Super-admin", "Administrator", 'Sport'],
                accessKey: frk["SIDEBAR-DIRECTORY-EXTRAMATERIALS"]
            },
            {
                title: "routes.legal_entities",
                to: "/directories/legal_entities",
                access: ["Super-admin", "Administrator", 'Sport'],
                accessKey: frk["SIDEBAR-DIRECTORY-MERCHANTS"]
            },
            {
                title: "routes.checks",
                to: "/directories/checks",
                access: ["Super-admin", "Administrator", 'Sport'],
                accessKey: frk["SIDEBAR-DIRECTORY-CHECKS"]
            },
        ]
    }
];

const flattenRoutesFun = (source, parent) =>
    source.reduce((acc, route) => {
        const builtRoute = {
            title: route.title,
            to: route.to,
            icon: route.icon || (parent && parent.icon) || "",
            breadcrumbs: ((parent && parent.breadcrumbs) || []).concat([route.title])
        };
        return acc.concat(builtRoute).concat(flattenRoutesFun(route.children || [], builtRoute))
    }, []);

const flattenRoutes = flattenRoutesFun(routes).reverse();

export const getCurrentRoute = to =>
    flattenRoutes.find(route => to.startsWith(route.to));