import React from 'react'
import {field} from "../../generic/tables";
import EditModalChildren from "./EditModalChildren";
import DirectoryTemplate from "../DirectoryTemplate";
import {FaCheck, FaTimes} from "react-icons/fa";
import {COUNTRIES} from "sv-common/constants/bases";
import {ENTITIES, getPermissions} from "../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";
import getCountryValue from '../../../lib/utils/getCountryValue';
import SubscriptionsExtraButtons from './SubscriptionsExtraButtons';

const columns = [
  field(t('directories.subscriptions.name'), true, "name"),
  field(t('directories.subscriptions.country'), true, "country",
    res => COUNTRIES[res.country]),

  field(t('directories.subscriptions.price'), true, "price_value",
    res => res.price_value + ' ' + res.price_currency),

  field(t('directories.subscriptions.active'), true, "active",
    res => res.active ? <FaCheck color={'green'}/> : <FaTimes color={'red'}/>),

  field(t('directories.subscriptions.commonDiscount'), true, "keel_all_luch_group_discount_size",
    res => res.keel_all_luch_group_discount_size + '%'),
];

const getColumns = () => {
  const extraColumns = [
    field(t('directories.subscriptions.raceDiscount'), true, "race_discount_size", res => (getCountryValue({ en: res.cup_discount_size, ru: res.race_discount_size }) || 0) + '%'),
  ];

  const countryColumns = getCountryValue({
    ru: [
      field(t('directories.subscriptions.individualDiscount'), true, "luch_individual_discount_size", res => res.luch_individual_discount_size + '%'),
    ],
    en: [
      field(t('directories.subscriptions.sailGPDiscount'), true, "sail_gp_discount_size", res => (res.sail_gp_discount_size || 0) + '%'),
    ]
  });

  countryColumns && extraColumns.unshift(...countryColumns);

  return [...columns, ...extraColumns];
}

const Subscriptions = (props) => {
    return <DirectoryTemplate
        fields={getColumns()}
        entityName={'subscriptions'}
        EditModalChildren={EditModalChildren}
        ExtraButtons={SubscriptionsExtraButtons}
        editable
        deletable
        filters={{_sort: 'price_value:ASC'}}
        permissions={getPermissions(ENTITIES.SUBSCRIPTIONS)}
        {...props}
    />
}


export default Subscriptions;