import { adventureComponentTypes } from '../constants';
import { openInNewTab } from '../../../lib/utils/helper';
import { ENTITIES } from '../../../lib/utils/hooks/useRoleSystem';

const adventureViewUrl = (url, entity) => {
  return `/${entity}/view/${url}`;
}

const adventureEditUrl = (url, entity,type) => {
  return `/${entity}${type ? '/'+type : ''}/edit${url ? '/'+url : ""}`;
}

const onNewPage = (url, is_school, is_camp, is_charter, is_push, history, type, is_edit) => {
  const method = (is_push ? history.push : openInNewTab);
  const action = (is_edit ? adventureEditUrl : adventureViewUrl);

  if (is_school) {
    return method(action(url, `school/practices`, type));
  }

  if (is_camp) {
    return method(action(url, adventureComponentTypes.camps, type));
  }

  if (is_charter) {
    return method(action(url, adventureComponentTypes.charters, type));
  }

  return method(action(url, adventureComponentTypes.adventures, type));
}

const onRoleEntity = (is_school, is_camp, is_charter, type) => {
  if (is_school) return ENTITIES.GROUPS_PRACTICE;

  if (type === 'world') {
    if (is_camp) {
      return ENTITIES.CAMPS_WORLD;
    }

    if (is_charter) {
      return ENTITIES.CHARTERS_WORLD;
    }

    return ENTITIES.ADVENTURES_WORLD;
  }

  return is_camp ? ENTITIES.CAMPS_RUSSIA : ENTITIES.ADVENTURES_RUSSIA;
}

export { onNewPage, onRoleEntity };