import { useGlobalConfigApi } from '../../../lib/api/globalConfig';
import { Col, FormGroup, CustomInput } from "reactstrap"
import { useState, useEffect } from "react";
import {t} from "i18next";

const SubscriptionsExtraButtons = () => {
    const {getGlobalSettings, saveGlobalSettings} = useGlobalConfigApi('global-settings');
    const [isPromoSubscription, setIsPromoSubscription] = useState(false);

    useEffect(() => {
        getGlobalSettings().then((data) => {
            data.config?.promo_subscription_available && 
            setIsPromoSubscription(data.config.promo_subscription_available) 
        })
    }, [])

    const onChangePromoSubscription = async (v) => {
        await saveGlobalSettings({ promo_subscription_available: v });
        setIsPromoSubscription(v);
    }


    return <Col>
                <FormGroup>
                    <CustomInput type='checkbox' className={'custom-switch pt-2'} id='promo_subscription_available' 
                                name='promo_subscription_available' checked={isPromoSubscription} 
                                onClick={() => onChangePromoSubscription(!isPromoSubscription)}
                                label={t('management.promoCodes.useForAbonements')}
                                />
                </FormGroup>
            </Col>
}

export default SubscriptionsExtraButtons