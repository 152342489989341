const initialState = {
    name: '',
    country: '',
    city: '',
    coordinates: '',
    price_currency: 'RUB',
    partial_payment_option_20: false,
    partial_payment_option_50: false,
    partial_payment_option_100: true,
    public_contract_url: '',
    req_fields: [],
    difficulty_level_id: {},
    extra_materials: [],
    files: undefined,
    loading: true,
    check_age_16: true,

    reload: false
};

const reducer = (state, action) => {
    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        return {[name]: value};
    }

    switch (action.type) {
        // Assistance block
        case 'changeActiveTab':
            // value: 'General'
            return {
                ...state,
                activeTab: action.payload
            }
        case 'setLoading':
            // value: false'
            return {
                ...state,
                loading: action.payload
            }

        // Data editing block
        case 'setForm':
            // value: {exampleId: 'exampleKey'}
            return {
                ...state,
                ...action.payload,
                loading: false
            }

        case 'setFormData':
            // value: SyntheticEvent
            return {
                ...state,
                ...handleInputChange(action.payload)
            }

        case 'clearDiscountData':
            // value: 'example'
            const discount = {}
            discount[action.payload + '_discount_type'] = 'summ'
            discount[action.payload + '_discount_size'] = 0.0
            return {
                ...state,
                ...discount
            }
            
        case 'setImg':
            // value: File
            return {
                ...state,
                files:
                action.payload
            }

        case 'refreshFields':
            return {...initialState}
        default:
            return state
    }
}

export {reducer, initialState};