import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types'
import {Col, FormGroup, Input, Label, Row} from "reactstrap";
import {useDefaultEndpoints} from "../../../../../lib/api/useDefaultEndpoints";
import {Controller} from "react-hook-form";
import FieldWithValidation from "../../../../generic/fields/FieldWithValidation";
import {
    UrlRHF,
    CountryRHF,
    CityRHF,
    PriceCurrencyRHF,
    TimeZonePickerRHF
} from "../../../../common/reactHookFormsFields";
import {TextControllerRHF} from "../../../../generic/fields";
import ConditionalField from "../../../../generic/fields/ConditionalField";
import {maxLength} from "../../../../common/reactHookFormsFields/rules";
import CheckSelector from "../../../../generic/fields/CheckSelector";
import {CHECK_TYPES} from "sv-common/constants/checks";
import {t} from "i18next";

const MainInfoBlock = ({state, dispatch, control, errors, isArrival, isSchool, setValue}) => {
    const setAdventureData = (e) => dispatch({type: 'setFormData', payload: e})

    const {getEntries} = useDefaultEndpoints('legal-entities');
    const [legalEntities, setLegalEntites] = useState([])

    const getPublicContracts = useDefaultEndpoints('public-contracts').getEntries;
    const [publicContracts, setPublicContracts] = useState([]);

    useEffect(() => {
        getEntries({}).then(res => setLegalEntites(() => res));
        getPublicContracts({}).then(res => setPublicContracts(() => res));
    }, [])

    return (<Row>
            <Col xs={12} md={6}>
                <FormGroup>
                    <CountryRHF state={state} setState={e => dispatch({type: 'setFormData', payload: e})}
                            form={{control, errors}}/>
                </FormGroup>
            </Col>
            <Col xs={12} md={6}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="legal_entity_id"
                        defaultValue={state.legal_entity_id?.id || state.legal_entity_id || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} label={t('adventures.edit.legalEntity')}
                                                 name={name} isRequired={true}
                            >
                                <Input type="select" id={name} invalid={invalid} name={name} onBlur={onBlur}
                                       style={{border: invalid ? '1px solid #dc3545' : '0'}}
                                       value={value}
                                       onChange={(e) => {
                                           setAdventureData(e);
                                           onChange(e.target.value)
                                       }}>
                                    <option disabled value={''}>{t('adventures.edit.pickLegalEntity')}</option>
                                    {legalEntities.map(legalEntity => <option key={legalEntity.id} value={legalEntity.id}>{legalEntity.name}</option>)}
                                </Input>
                            </FieldWithValidation>
                        }
                    />
                </FormGroup>
            </Col>
            <Col xs={12} md={6}>
                <FormGroup>
                    <FormGroup>
                        <CityRHF state={state} setState={e => dispatch({type: 'setFormData', payload: e})}
                                    form={{control, errors}}/>
                    </FormGroup>
                </FormGroup>
            </Col>
            <Col xs={4} md={2}>
                <FormGroup>
                    <TextControllerRHF inputName={'total_sits'}
                                       setValue={e => dispatch({type: 'setFormData', payload: e})}
                                       label={t('adventures.edit.totalSits')}
                                       control={control}
                                       errors={errors}
                                       defaultValue={state.total_sits || ''}
                                       placeholder={'10'}
                                       isRequired={true}
                                       rules={{required: t('inputs.validation.required')}}
                                       setValueRHF={setValue}
                                       info={t('common.totalSitsDecrease')}
                    />
                </FormGroup>
            </Col>
            <Col xs={4} md={2}>
                <FormGroup>
                    <TextControllerRHF inputName={'price_value'}
                                       label={t('adventures.edit.price')}
                                       setValue={e => dispatch({type: 'setFormData', payload: e})}
                                       control={control}
                                       errors={errors}
                                       defaultValue={state.price_value || ''}
                                       placeholder={'1000'}
                                       isRequired={true}
                                       rules={{required: t('inputs.validation.required')}}
                                       setValueRHF={setValue}
                    />
                </FormGroup>
            </Col>
            <Col xs={4} md={2}>
                <FormGroup>
                    <PriceCurrencyRHF state={state}
                                      setState={e => dispatch({type: 'setFormData', payload: e})}
                                      form={{control, errors}}/>
                </FormGroup>
            </Col>
            <Col xs={12} md={6}>
                <FormGroup>
                    <UrlRHF state={state} setState={e => dispatch({type: 'setFormData', payload: e})}
                            form={{control, errors}} label={`URL ${isArrival
                                ? t('adventures.edit.arrival') : t('adventures.edit.travel')}`}
                            hint={t('adventures.edit.urlHint')} isTrimOnChange={true} />
                </FormGroup>
            </Col>
            <Col xs={12} md={6}>
                <FormGroup inline className={'mb-0'}>
                    <Label check for="partial_payment_option">
                        {t('adventures.edit.partialPayments')}
                    </Label>
                </FormGroup>
                <Row>
                    <ConditionalField condition={!state.is_school && !isSchool}>
                        <Col xs={12} md={4}>
                            <FormGroup check>
                                <input type="checkbox" name="partial_payment_option_20" id="partial_payment_option_20"
                                       checked={state.partial_payment_option_20}
                                       onChange={setAdventureData}/>
                                <Label check for="partial_payment_option1" className={'d-inline'}>
                                    20%
                                </Label>
                            </FormGroup>
                        </Col>
                    </ConditionalField>
                    <Col xs={12} md={4}>
                        <FormGroup check>
                            <input type="checkbox" name="partial_payment_option_50" id="partial_payment_option_50"
                                   checked={state.partial_payment_option_50}
                                   onChange={setAdventureData}/>
                            <Label check for="partial_payment_option2" className={'d-inline'} >
                                50%
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={4}>
                        <FormGroup check>
                            <input type="checkbox" value={'100%'} checked disabled/>
                            <Label check for="partial_payment_option2" className={'d-inline'} >
                                100%
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
            </Col>
            <Col xs={12} md={6}>
                <FormGroup>
                    <Label>{t('adventures.edit.coordinates')}</Label>
                    <Input name={'coordinates'} id={'coordinates'} value={state?.coordinates || ''}
                           onChange={setAdventureData}/>
                </FormGroup>
            </Col>
            <Col xs={12} md={6}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="public_contract_url"
                        defaultValue={state.public_contract_url}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} label={t('adventures.edit.legalEntities')}
                                                 name={name} isRequired={true}
                            >
                                <Input  type="select" id={name} invalid={invalid} name={name} onBlur={onBlur}
                                        style={{border: invalid ? '1px solid #dc3545' : '0'}}
                                        value={value}
                                        onChange={e => {
                                            setAdventureData(e)
                                            onChange(e.target.value)
                                        }}>
                                    <option value={''} disabled>{t('adventures.edit.pickContract')}</option>
                                    {publicContracts.map(publicContract => <option value={publicContract.link} key={publicContract.id}>{publicContract.tag}</option>)}
                                </Input>
                            </FieldWithValidation>
                        }
                    />
                </FormGroup>
            </Col>
            <Col xs={12} md={6}>
                <FormGroup>
                    <TextControllerRHF inputName={'payment_description'} control={control} setValue={setAdventureData}
                                       label={t('adventures.edit.paymentDesc')} errors={errors}
                                       defaultValue={state.payment_description || ''}
                                       rules={{...maxLength(100)}}
                                       placeholder={'Big spring regatta SILAVETRA, 01.01 - 01.02.2020, STEPAN PROBKA'}
                                       hint={t('adventures.edit.paymentHint')} setValueRHF={setValue}
                    />
                </FormGroup>
            </Col>
            <Col xs={12} md={6}>
                <FormGroup>
                    <TimeZonePickerRHF form={{control, errors}} setState={setAdventureData} state={state} isRequired/>
                </FormGroup>
            </Col>
            <Col xs={12} md={6}>
                <CheckSelector control={control} dispatch={dispatch} defaultValue={state.check_id?.id}
                               productName={state.name} errors={errors} checkTypes={CHECK_TYPES} deps={[state.name]} />
            </Col>
        </Row>
    )
}

export default MainInfoBlock;

MainInfoBlock.propTypes = {
    state: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,

}