import React from 'react'
import {ClientData, UserData, ClientHistory, Subscription} from './tabs'
import {OrdersList} from "../../orders";
import {t} from "i18next";

const tabList = ({state, dispatch, control, errors, ...rest}) => {
    return (
        [
            {
                name: t('management.users.data'),
                child: <ClientData state={state} dispatch={dispatch} control={control} errors={errors}/>,
                isDisplayed: true,
                url: 'personal'
            },
            {
                name: t('management.users.technicalData'),
                child: <UserData state={state} dispatch={dispatch} control={control} errors={errors}/>,
                isDisplayed: true,
                url: 'tech'
            },
            {
                name: t('management.users.purchaseHistory'),
                child: <OrdersList user_id={state?.user_data?.id} client_id={state.id} isInline/>,
                isDisplayed: !!state.id,
                url: 'orders'
            },
            {
                name: t('management.users.subscription'),
                child: <Subscription state={state} dispatch={dispatch}/>,
                isDisplayed: !!state.id,
                url: 'subscription-changes'
            },
            {
                name: t('management.users.eventsHistory'),
                child: <ClientHistory state={state}/>,
                isDisplayed: !!state.id,
                url: 'events'
            },
        ]
    )
}

export default tabList;