import {field} from "../../../../generic/tables";
import React from "react";
import {withLinkPhone} from "../../../../../lib/utils/helpers/interfaceFeatures";
import {Col, Row} from "reactstrap";
import i18next, {t} from "i18next";
import { IS_RUSSIA, LEVEL_TYPES } from '../../../../../lib/utils/constants';
import ElementWithAccessInCountries from '../../../../../lib/utils/hocs/ElementWithAccessInCountries';
import { sailingExperienceFromRuToEN } from 'sv-common/functions/formatters';

const getLevelType = (trainingCounts) => {
  if (trainingCounts <= 3) {
    return LEVEL_TYPES.beginner;
  } else if (trainingCounts >= 4 && trainingCounts <= 10) {
    return LEVEL_TYPES.experienced;
  } else {
    return LEVEL_TYPES.advanced;
  }
}

const getLevelNumberByType = (type) => {
  switch (type) {
    case LEVEL_TYPES.beginner: return 1;
    case LEVEL_TYPES.experienced: return 2;
    case LEVEL_TYPES.advanced: return 3;
    default: return 0;
  }
}

const getLevelTitle = (trainingCount) => {
  switch (getLevelType(trainingCount)) {
    case LEVEL_TYPES.beginner:
      return t('common.productOrderList.level.beginner');
    case LEVEL_TYPES.experienced:
      return t('common.productOrderList.level.experienced');
    case LEVEL_TYPES.advanced:
      return t('common.productOrderList.level.advanced');
    default:
      return '';
  }
}

export const getLevelBadges = (sailing_experience, trainingCounts = 0) => {
  const exp_rate = [];
  let level;
  let total;

  if (IS_RUSSIA) {
    [level, total] = sailing_experience?.split('/') || [0, 7];
  } else {
    if (sailing_experience) {
      [level, total] = sailingExperienceFromRuToEN(sailing_experience)?.split('/') || [1, 3];
    } else {
      level = getLevelNumberByType(getLevelType(trainingCounts));
      total = 3;
    }
  }

  for (let i = 0; i < total; i++) {
    exp_rate.push(<div key={i}
                       className={`d-inline badge badge${i < level ? '' : '-outline'}-success experience-badge `}/>)
  }

  return <Row><Col>{exp_rate.map(badge => badge)}</Col></Row>
}

export const getSlotSet = () => {
  const { language } = i18next;

  const fields = [
    field(t('common.productOrderList.name'), false, "client_id.first_name", res => res.paid_for_ids[0]?.first_name),
    field(t('common.productOrderList.lastname'), false, "client_id.last_name", res => res.paid_for_ids[0]?.last_name),
    field(t('common.productOrderList.phone'), false, "client_id.phone", res =>
      withLinkPhone(res.contact_phone || res.paid_for_ids[0]?.phone, 'phone')
    ),
  ];

  if (IS_RUSSIA) {
    fields.push(field(t('common.productOrderList.instructor'), false, "instructor_id", res => {
      if (res.coachless_discount_applied)
        return t('common.productOrderList.withoutInstructor')
      if (!res.instructor_id)
        return t('common.productOrderList.anyInstructor')
      return <>{`${res.instructor_id?.first_name || ''} ${res.instructor_id?.last_name || ''}`}</>
    }));
  }

  fields.push(
    field(t('common.productOrderList.count'), false, "paid_for_length"),
    field(t('common.productOrderList.experience'), false, "client_id.sailing_experience", res => <div className={`experience-block-${language}`}>
        {getLevelBadges(res.paid_for_ids[0]?.sailing_experience, res.client_id?.slots_visited || 0)}
        <ElementWithAccessInCountries hideInCountries={['EN']}>
          <hr className={'experience-divider'}/>
        </ElementWithAccessInCountries>
        <Row>
          <Col>
            Σ: {res.client_id?.slots_visited || '0'} <ElementWithAccessInCountries hideInCountries={['RU']}><>- {getLevelTitle(res.client_id?.slots_visited || 0)}</></ElementWithAccessInCountries>
          </Col>
        </Row>
      </div>
    ),
    field(t('common.productOrderList.comment'), false, "comment", res =>
      <>
        {
          res?.comment?.match(/.{1,50}/g)
            ?.map(line => <div>{line}</div>)
        }
      </>),
    field(t('common.productOrderList.email'), false, "client_id.email", res => res.paid_for_ids[0]?.email))

  return fields;
}