import {useApi} from "../../api/useApi";
import {getCookie, setCookie} from "../cookies";

const VIEW_ONLY = {edit: false, view: true};
const FULL = {edit: true, view: true};
const NO_ACCESS = {edit: false, view: false}

export const ENTITIES = {
    BASES: 'bases',
    INSTRUCTORS: 'instructors',
    EVENTS: 'events',
    SLOTS: 'slots',
    ADVENTURES_RUSSIA: 'adventures_russia',
    ADVENTURES_WORLD: 'adventures_world',
    GROUPS_THEORY: 'groups_theory',
    GROUPS_PRACTICE: 'groups_practice',
    GROUPS_LECTURES: 'groups_lectures',
    ORDERS: 'orders',
    CAPTAINS: 'captains',
    PROMOCODES: 'promocodes',
    CERTIFICATES: 'certificates',
    BLACKLIST: 'blacklist',
    REVIEWS: 'reviews',
    PROMOCODE_PACKAGES: 'promocode_packages',
    EXPORT: 'export',
    CLIENTS: 'clients',
    LINK_GEN: 'link_generator',
    SUBSCRIPTIONS: 'subscriptions',
    DIFFICULTIES: 'difficulties',
    USER_FIELDS: 'user_fields',
    TEMPLATES: 'templates',
    CURRENCIES: 'currencies',
    BOAT_CLASSES: 'boat_classes',
    CORP_CLIENTS: 'corp_clients',
    TRANSACTION_STATUS: 'transaction_status',
    SHIRT_SIZES: 'shirt_sizes',
    LEGAL_ENTITIES: 'legal_entities',
    EXTRA_MATERIALS: 'extra_materials',
    PUBLIC_CONTRACTS: 'public_contracts',
    CHECKS: 'checks',
    CAMPS_RUSSIA: 'camps_russia',
    CAMPS_WORLD: 'camps_world',
    CHARTERS_WORLD: 'charters_world',
    USER_ROLE: 'user_role',
    EXPORT_BY_DIRECTION: 'export_by_direction',
    COORDINATORS: 'coordinators',
    BASE_ADDRESSES: 'base_addresses',
    AREAS: 'areas',
}

/**
 * Permission.
 * @typedef {{edit: boolean, view: boolean}} Permission
 */

/**
 *
 * @param {Permission} bases
 * @param {Permission} events
 * @param {Permission} slots
 * @param {Permission} advRussia
 * @param {Permission} advWorld
 * @param {Permission} groupsTheory
 * @param {Permission} groupsPractice
 * @param {Permission} groupsLectures
 * @param {Permission} orders
 * @param {Permission} instructors
 * @param {Permission} captains
 * @param {Permission} promocodes
 * @param {Permission} certificates
 * @param {Permission} blacklist
 * @param {Permission} reviews
 * @param {Permission} pPackages
 * @param {Permission} exports
 * @param {Permission} clients
 * @param {Permission} linkGen
 * @param {Permission} subscriptions
 * @param {Permission} difficulties
 * @param {Permission} userFields
 * @param {Permission} templates
 * @param {Permission} currencies
 * @param {Permission} boatClasses
 * @param {Permission} shirtSizes
 * @param {Permission} publicContracts
 * @param {Permission} extraMaterials
 * @param {Permission} legalEntities
 * @param {Permission} checks
 * @param corpClients
 * @param campsWorld
 * @param campsRuss
 * @param transStatus
 * @param userRole
 * @param exportByDirection
 * @returns {{"[ENTITIES.GROUPS_THEORY]", "[ENTITIES.SUBSCRIPTIONS]", "[ENTITIES.CLIENTS]", "[ENTITIES.PUBLIC_CONTRACTS]", "[ENTITIES.INSTRUCTORS]", "[ENTITIES.BASES]", "[ENTITIES.ADVENTURES_RUSSIA]", "[ENTITIES.USER_FIELDS]", "[ENTITIES.REVIEWS]", "[ENTITIES.BOAT_CLASSES]", "[ENTITIES.SHIRT_SIZES]", "[ENTITIES.LINK_GEN]", "[ENTITIES.GROUPS_LECTURES]", "[ENTITIES.EXPORT]", "[ENTITIES.EVENTS]", "[ENTITIES.PROMOCODES]", "[ENTITIES.PROMOCODE_PACKAGES]", "[ENTITIES.TEMPLATES]", "[ENTITIES.BLACKLIST]", "[ENTITIES.GROUPS_PRACTICE]", "[ENTITIES.CAPTAINS]", "[ENTITIES.SLOTS]", "[ENTITIES.CHECKS]", "[ENTITIES.CERTIFICATES]", "[ENTITIES.DIFFICULTIES]", "[ENTITIES.ORDERS]", "[ENTITIES.ADVENTURES_WORLD]", "[ENTITIES.EXTRA_MATERIALS]", "[ENTITIES.LEGAL_ENTITIES]", "[ENTITIES.CURRENCIES]"}}
 * @constructor
 */
const GENERATE_PERMISSIONS = (bases, events, slots, advRussia, advWorld, groupsTheory, groupsPractice,
                              groupsLectures, orders, instructors, captains, promocodes, certificates, blacklist,
                              reviews, pPackages, exports, clients, linkGen, subscriptions, difficulties,
                              userFields, templates, currencies, boatClasses, shirtSizes, publicContracts,
                              extraMaterials, legalEntities, checks, coordinators, corpClients, campsWorld,
                              campsRuss, transStatus, userRole, exportByDirection, baseAddresses, areas,
                              chartersWorld) => (
    {
        [ENTITIES.BASES]: bases,
        [ENTITIES.EVENTS]: events,
        [ENTITIES.SLOTS]: slots,
        [ENTITIES.ADVENTURES_RUSSIA]: advRussia,
        [ENTITIES.ADVENTURES_WORLD]: advWorld,
        [ENTITIES.GROUPS_THEORY]: groupsTheory,
        [ENTITIES.GROUPS_PRACTICE]: groupsPractice,
        [ENTITIES.GROUPS_LECTURES]: groupsLectures,
        [ENTITIES.ORDERS]: orders,
        [ENTITIES.INSTRUCTORS]: instructors,
        [ENTITIES.CAPTAINS]: captains,
        [ENTITIES.PROMOCODES]: promocodes,
        [ENTITIES.CERTIFICATES]: certificates,
        [ENTITIES.BLACKLIST]: blacklist,
        [ENTITIES.REVIEWS]: reviews,
        [ENTITIES.PROMOCODE_PACKAGES]: pPackages,
        [ENTITIES.EXPORT]: exports,
        [ENTITIES.CLIENTS]: clients,
        [ENTITIES.LINK_GEN]: linkGen,
        [ENTITIES.SUBSCRIPTIONS]: subscriptions,
        [ENTITIES.DIFFICULTIES]: difficulties,
        [ENTITIES.USER_FIELDS]: userFields,
        [ENTITIES.TEMPLATES]: templates,
        [ENTITIES.CURRENCIES]: currencies,
        [ENTITIES.BOAT_CLASSES]: boatClasses,
        [ENTITIES.SHIRT_SIZES]: shirtSizes,
        [ENTITIES.PUBLIC_CONTRACTS]: publicContracts,
        [ENTITIES.EXTRA_MATERIALS]: extraMaterials,
        [ENTITIES.LEGAL_ENTITIES]: legalEntities,
        [ENTITIES.CORP_CLIENTS]: corpClients,
        [ENTITIES.TRANSACTION_STATUS]: transStatus,
        [ENTITIES.CHECKS]: checks,
        [ENTITIES.CAMPS_WORLD]: campsWorld,
        [ENTITIES.CAMPS_RUSSIA]: campsRuss,
        [ENTITIES.USER_ROLE]: userRole,
        [ENTITIES.EXPORT_BY_DIRECTION]: exportByDirection,
        [ENTITIES.COORDINATORS]: coordinators,
        [ENTITIES.BASE_ADDRESSES]: baseAddresses,
        [ENTITIES.AREAS]: areas,
        [ENTITIES.CHARTERS_WORLD]: chartersWorld,
    }
)

const PERMISSIONS = {
    'Super-admin': GENERATE_PERMISSIONS({...FULL}, {...FULL}, {...FULL}, {...FULL},
      {...FULL}, {...FULL}, {...FULL}, {...FULL}, {...FULL},
      {...FULL}, {...FULL}, {...FULL}, {...FULL},
      {...FULL}, {...FULL}, {...FULL}, {...FULL}, {...FULL},
      {...FULL}, {...FULL}, {...FULL}, {...FULL}, {...FULL},
      {...FULL}, {...FULL}, {...FULL}, {...FULL},
      {...FULL}, {...FULL}, {...FULL}, {...FULL}, {...FULL}, {...FULL},
      {...FULL}, {...FULL}, {...FULL}, {...FULL}, {...FULL}, {...FULL}, {...FULL}
    ),
    Administrator: GENERATE_PERMISSIONS({...FULL}, {...FULL}, {...FULL}, {...FULL},
        {...FULL}, {...FULL}, {...FULL}, {...FULL}, {...FULL},
        {...FULL}, {...FULL}, {...FULL}, {...FULL},
        {...FULL}, {...FULL}, {...FULL}, {...FULL}, {...FULL},
        {...FULL}, {...FULL}, {...FULL}, {...FULL}, {...FULL},
        {...FULL}, {...FULL}, {...FULL}, {...FULL},
        {...FULL}, {...FULL}, {...FULL}, {...FULL}, {...FULL}, {...FULL},
        {...FULL}, {...NO_ACCESS}, {...NO_ACCESS}, {...FULL}, {...FULL}, {...FULL}, {...FULL}
    ),
    Sport: GENERATE_PERMISSIONS(
        {...VIEW_ONLY}, {...FULL}, {...FULL}, {...VIEW_ONLY}, {...VIEW_ONLY},
        {...VIEW_ONLY}, {...VIEW_ONLY}, {...VIEW_ONLY}, {...FULL},
        {...FULL}, {...VIEW_ONLY}, {...FULL}, {...FULL}, {...FULL},
        {...VIEW_ONLY}, {...FULL}, {...FULL}, {...FULL}, {...FULL},
        {...FULL}, {...VIEW_ONLY}, {...VIEW_ONLY}, {...VIEW_ONLY},
        {...VIEW_ONLY}, {...FULL}, {...VIEW_ONLY}, {...FULL},
        {...FULL}, {...VIEW_ONLY}, {...VIEW_ONLY}, {...NO_ACCESS}, {...NO_ACCESS}, {...NO_ACCESS},
        {...NO_ACCESS}, {...NO_ACCESS}, {...NO_ACCESS}, {...VIEW_ONLY}, {...VIEW_ONLY}, {...VIEW_ONLY}, {...FULL}
    ),
    'Call-center': GENERATE_PERMISSIONS(
        {...VIEW_ONLY},{...VIEW_ONLY},{...VIEW_ONLY},{...VIEW_ONLY},
        {...VIEW_ONLY},{...VIEW_ONLY},{...VIEW_ONLY},{...VIEW_ONLY},
        {...VIEW_ONLY},{...VIEW_ONLY},{...VIEW_ONLY},{...VIEW_ONLY},
        {...FULL},{...VIEW_ONLY},{...VIEW_ONLY},{...NO_ACCESS},
        {...NO_ACCESS},{...VIEW_ONLY}, {...VIEW_ONLY},{...NO_ACCESS},
        {...NO_ACCESS},{...NO_ACCESS},{...NO_ACCESS},{...NO_ACCESS},
        {...NO_ACCESS},{...NO_ACCESS},{...NO_ACCESS},{...NO_ACCESS},
        {...NO_ACCESS},{...NO_ACCESS},{...NO_ACCESS},{...NO_ACCESS},{...NO_ACCESS}, {...NO_ACCESS},
        {...NO_ACCESS}, {...NO_ACCESS}, {...VIEW_ONLY}, {...VIEW_ONLY}, {...VIEW_ONLY}, {...FULL}
    )
}

export const useRoleSystem = (entity) => {
    const {userRole} = useApi()
    return PERMISSIONS[userRole][entity] || NO_ACCESS
}

/**
 *
 * @param entity
 * @returns {Permission}
 */
export const getPermissions = (entity) => {
    const userRole = getCookie('sv_admin_role');
    return PERMISSIONS[userRole][entity] || NO_ACCESS
}