import {Button, Col, CustomInput, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {DatePickerRHF, SelectControllerRHF, TextControllerRHF} from "../../../generic/fields";
import {t} from "i18next";
import {requiredRule} from "../../../common/reactHookFormsFields/rules";
import {PhoneRHF, TimePickerRHF} from "../../../common/reactHookFormsFields";
import {getCountryCode} from "../../../../lib/utils/helper";
import Dropzone from "./Dropzone";
import {useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {useDefaultEndpoints} from "../../../../lib/api/useDefaultEndpoints";
import {API_URL} from "../../../../lib/api/useApi";
import {useHistory, useParams} from "react-router-dom";
import {TableCard} from "../../../generic/cards";
import {CopyLink} from "../../../generic/buttons";
import {withBreadcrumbs} from "../../../common/hoc/Breadcrumbs";
import usePages from "../usePages";
import ControllerRHF from "../../../generic/fields/ControllerRHF";
import {AsyncPaginate} from "react-select-async-paginate";

const Page = withBreadcrumbs(({entity}) => {
    const history = useHistory();
    const {id} = useParams();

    const [projects, setProjects] = useState();
    const [templates, setTemplates] = useState();
    const [coordinators, setCoordinators] = useState();
    const [baseAddresses, setBaseAddresses] = useState();
    const [areas, setAreas] = useState();

    const form = useForm();
    const {handleSubmit, reset, control, errors, setValue, watch} = form;

    const {getEntry, createEntry, editEntry} = useDefaultEndpoints('pages', true);
    const {getEntries: getProjects} = useDefaultEndpoints('projects');
    const {getEntries: getTemplates} = useDefaultEndpoints('templates');
    const {getEntries: getCoordinators} = useDefaultEndpoints('coordinators');
    const {getEntries: getBaseAddresses} = useDefaultEndpoints('base-addresses');
    const {getEntries: getAreas} = useDefaultEndpoints('areas');
    const {findAndCountEntries} = useDefaultEndpoints('constructor-directories');

    const {getPageFullURL} = usePages();
    const pageFullURL = getPageFullURL(
        projects?.find(project => project.id == watch('project')),
        watch('key')
    );

    const getProjectsOptions = () => ([
        <option value={''} disabled>{t('constructor.pages.pickProject')}</option>,
        projects?.map(project =>
            <option value={project.id} key={project.key}>{project.label}</option>
        )
    ])

    const getTemplatesOptions = () => ([
        <option value={''} disabled>{t('constructor.pages.pickTemplate')}</option>,
        templates?.map(template =>
            <option value={template.id} key={template.key}>{template.label}</option>
        )
    ])

    const getCoordinatorsOptions = () => ([
        <option value={''} disabled>{t('constructor.pages.coordinator')}</option>,
        coordinators?.map(coordinator =>
            <option value={coordinator.id} key={coordinator.id}>{coordinator.name}</option>
        )
    ])

    const getBaseAddressesOptions = () => ({ options: baseAddresses?.map(address =>
            ({label: address.name, value: address.id})
        )})

    const getAreasOptions = () => ({ options: areas?.map(area =>
        ({label: area.name, value: area.id})
    )})

    const onCreateOrUpdate = (data) => id
        ? editEntry(id, data).then( () => toast.success(t('common.success')) )
        : createEntry(data).then( () => history.push(`/constructor/${entity ? entity : 'pages'}/`) );

    const onSubmit = handleSubmit(data => {
        const {logo, base_addresses, areas, ...dataToSend} = data;
        if (entity === 'letters') dataToSend.is_letter = true;
        onCreateOrUpdate({
            ...dataToSend,
            base_addresses: base_addresses.map((address) => address.value),
            areas: areas.map((area) => area.value),
            files: logo
        })
    });

    const onLoadDirectory = (query, opts, { offset: prevOffset, limit: prevLimit, query: prevQuery }) => {
        const reqOpts = {"_limit": prevLimit, "_start": prevOffset};
        if (query) {
            reqOpts.description_contains = query;
        }

        return findAndCountEntries(reqOpts).then(res => {
            const offset = query === prevQuery ? prevOffset + 10 : 0;
            const hasMore = (offset + prevLimit) <= res.total;

            return {
                options: res.data?.map(i => ({ value: i.description, label: i.description })),
                hasMore,
                additional: {
                    offset,
                    query,
                    limit: prevLimit,
                }
            }
        }).catch(() => ({ options: [], hasMore: false }));
    }

    useEffect(() => {
        if (id) {
            getEntry(id).then(res => {
                Object.keys(res).forEach(key => {
                    if (res[key] === null) {
                        delete res[key];
                    }
                });

                if (res.project){
                    res.project = res.project.id
                }
                if (res.template){
                    res.template = res.template.id
                }

                reset({...res, coordinator: res.coordinator ? res.coordinator.id : undefined, 
                    logo: res.logo ? {...res.logo, preview: API_URL + res.logo.url} : {},
                    base_addresses: res?.base_addresses ? res.base_addresses.map((address) => ({label: address.name, value: address.id})) : {},
                    areas: res?.areas ? res.areas.map((area) => ({label: area.name, value: area.id})) : {}
                });
            })
        }

        getProjects().then(setProjects);
        getTemplates().then(setTemplates);
        getCoordinators().then(setCoordinators);
        getBaseAddresses().then(setBaseAddresses);
        getAreas().then(setAreas);
    }, [id]);

    return <TableCard>
        <div className={"row justify-content-end mb-3"}>
            <div className={"col-"}>
                <Button color="success" size={'sm'} className={'btn-rounded'} onClick={onSubmit}>
                    {t('common.save')}
                </Button>
            </div>
        </div>
        <Form>
            <Row className={'align-items-end'}>
                <Col lg={4} md={6} xs={12}>
                    <FormGroup>
                        <Label>URL External</Label>
                        <CopyLink href={pageFullURL(true)} showLink={false}/>
                        <Input name="url" value={pageFullURL(true)} disabled />
                    </FormGroup>
                </Col>
                <Col lg={4} md={6} xs={12}>
                    <FormGroup>
                        <Label>URL for testing</Label>
                        <CopyLink href={pageFullURL(false)} showLink={false}/>
                        <Input name="url" value={pageFullURL(false)} disabled />
                    </FormGroup>
                </Col>
                <Col lg={4} md={6} xs={12} className={'mb-1'}>
                    <FormGroup>
                        <ControllerRHF
                            name={'is_public'}
                            form={form}
                            render={(
                                {value, name, onChange}
                            ) =>
                                <CustomInput
                                    type="checkbox"
                                    className={'custom-switch'}
                                    id={name}
                                    name={name}
                                    label={t("constructor.public")} checked={value || false}
                                    onChange={(e) => onChange(e.target.checked)} />
                            }
                        />
                    </FormGroup>
                </Col>
                <Col lg={4} md={6} xs={12} className={'mb-1'}>
                    <FormGroup>
                        <ControllerRHF
                            name={'show_photo'}
                            form={form}
                            render={(
                                {value, name, onChange}
                            ) =>
                                <CustomInput
                                    type="checkbox"
                                    className={'custom-switch'}
                                    id={name}
                                    name={name}
                                    label={t("constructor.templates.show_photo")} checked={value || false}
                                    onChange={(e) => onChange(e.target.checked)} />
                            }
                        />
                    </FormGroup>
                </Col>
                <Col lg={4} md={6} xs={12} className={'mb-1'}>
                    <FormGroup>
                        <ControllerRHF
                            name={'show_discounts'}
                            form={form}
                            render={(
                                {value, name, onChange}
                            ) =>
                                <CustomInput
                                    type="checkbox"
                                    className={'custom-switch'}
                                    id={name}
                                    name={name}
                                    label={t("constructor.templates.show_discounts")} checked={value || false}
                                    onChange={(e) => onChange(e.target.checked)} />
                            }
                        />
                    </FormGroup>
                </Col>
                <Col lg={4} md={6} xs={12} className={'mb-1'}>
                    <FormGroup>
                        <ControllerRHF
                            name={'show_block_information'}
                            form={form}
                            render={(
                                {value, name, onChange}
                            ) =>
                                <CustomInput
                                    type="checkbox"
                                    className={'custom-switch'}
                                    id={name}
                                    name={name}
                                    label={t("constructor.templates.show_block_information")} checked={value || false}
                                    onChange={(e) => onChange(e.target.checked)} />
                            }
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg={4} md={6} xs={12}>
                    <FormGroup>
                        <SelectControllerRHF
                            inputName={"project"} control={control} defaultValue='' options={getProjectsOptions()}
                            label={t('constructor.pages.project')} isRequired errors={errors} rules={requiredRule}
                        />
                    </FormGroup>
                </Col>
                <Col lg={4} md={6} xs={12}>
                    <FormGroup>
                        <SelectControllerRHF
                            inputName={"template"} control={control} defaultValue='' options={getTemplatesOptions()}
                            label={t('constructor.pages.template')} isRequired errors={errors} rules={requiredRule}
                        />
                    </FormGroup>
                </Col>
                <Col lg={4} md={6} xs={12}>
                    <FormGroup>
                        <TextControllerRHF
                            inputName={'label'} control={control} errors={errors} label={t('constructor.label')}
                            isRequired defaultValue='' rules={requiredRule} placeholder={t('constructor.pages.labelPlaceholder')}
                        />
                    </FormGroup>
                </Col>
                <Col lg={4} md={6} xs={12}>
                    <FormGroup>
                        <TextControllerRHF
                            inputName={'card_template'} control={control} errors={errors} label={t('constructor.pages.cardTemplate')}
                            isRequired defaultValue='' rules={{...requiredRule, maxLength: { value: 200, message: t('common.forms.maxLength', {count: 200}) } }}
                            placeholder={t('constructor.pages.cardTemplatePlaceholder')}
                        />
                    </FormGroup>
                </Col>
                <Col lg={4} md={6} xs={12}>
                    <FormGroup>
                        <TextControllerRHF
                            inputName={'key'} control={control} errors={errors} placeholder={t('constructor.keyPlaceholder')}
                            label={t('constructor.key')} isRequired defaultValue='' rules={requiredRule} isDeleteSpaces
                        />
                    </FormGroup>
                </Col>
                <Col lg={4} md={6} xs={12}>
                    <FormGroup>
                        <DatePickerRHF
                            isRequired control={control} errors={errors} inputName={'start_date'}
                            label={t('constructor.templates.start_date')} rules={requiredRule} defaultValue=''
                        />
                    </FormGroup>
                </Col>
                <Col lg={4} md={6} xs={12}>
                    <FormGroup>
                        <TimePickerRHF control={control} errors={errors} isRequired defaultValue='' name="start_time"
                                       label={t('constructor.templates.start_time')} setEntity={(value) => {
                                           setValue('start_time', value)
                                       }}
                        />
                    </FormGroup>
                </Col>
                <Col lg={4} md={6} xs={12}>
                    <FormGroup>
                        <TimePickerRHF control={control} errors={errors} isRequired defaultValue='' name="end_time"
                                       label={t('constructor.templates.end_time')} setEntity={(value) => {
                                           setValue('end_time', value)
                                       }}
                        />
                    </FormGroup>
                </Col>
                <Col lg={4} md={6} xs={12}>
                    <FormGroup>
                        <TextControllerRHF
                            inputName={'rules_link'} control={control} errors={errors} isRequired defaultValue=''
                            placeholder={t('constructor.pages.rulesLinkPlaceholder')}
                            label={t('constructor.templates.rules_link')} rules={requiredRule}
                        />
                    </FormGroup>
                </Col>
                <Col lg={4} md={6} xs={12}>
                    <FormGroup>
                        <TextControllerRHF
                            inputName={'photos_link'} control={control} errors={errors} isRequired defaultValue=''
                            placeholder={t('constructor.pages.photosLinkPlaceholder')}
                            label={t('constructor.templates.photos_link')} rules={requiredRule}
                        />
                    </FormGroup>
                </Col>
                <Col lg={4} md={6} xs={12}>
                    <FormGroup>
                        <Label>{t('constructor.pages.base_address')}</Label>
                        <ControllerRHF
                            name={'base_addresses'}
                            form={form}
                            defaultValue={[]}
                            render={(
                                {value, onChange}
                            ) => {
                                return <AsyncPaginate
                                    value={value}
                                    loadOptions={getBaseAddressesOptions}
                                    isMulti
                                    onChange={(values) => onChange(values || [])}
                                    debounceTimeout={1000}
                                    closeMenuOnSelect={false}
                                    additional={{ offset: 0, limit: 10, query: "" }}
                                />
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col lg={4} md={6} xs={12}>
                    <FormGroup>
                        <Label>{t('constructor.pages.areas')}</Label>
                        <ControllerRHF
                            name={'areas'}
                            form={form}
                            defaultValue={[]}
                            render={(
                                {value, onChange}
                            ) => {
                                return <AsyncPaginate
                                    value={value}
                                    loadOptions={getAreasOptions}
                                    isMulti
                                    onChange={(values) => onChange(values || [])}
                                    debounceTimeout={1000}
                                    closeMenuOnSelect={false}
                                    additional={{ offset: 0, limit: 10, query: "" }}
                                />
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col lg={4} md={6} xs={12}>
                    <FormGroup>
                        <SelectControllerRHF
                            inputName={"coordinator"} control={control} defaultValue='' options={getCoordinatorsOptions()}
                            label={t('constructor.pages.coordinator')} isRequired errors={errors} rules={requiredRule}
                        />
                    </FormGroup>
                </Col>
                <Col lg={4} md={6} xs={12}>
                    <FormGroup>
                        <TextControllerRHF
                            inputName={'more_link'} control={control} errors={errors} isRequired defaultValue=''
                            placeholder={t('constructor.pages.moreLinkPlaceholder')}
                            label={t('constructor.templates.more_link')} rules={requiredRule}
                        />
                    </FormGroup>
                </Col>
                <Col lg={4} md={6} xs={12}>
                    <FormGroup>
                        <PhoneRHF form={form} state={{}} name={'phone'} country={getCountryCode()}
                                  label={t('constructor.templates.phone')}
                        />
                    </FormGroup>
                </Col>
                <Col lg={4} md={6} xs={12}>
                    <Dropzone form={form}/>
                </Col>
                <Col lg={4} md={6} xs={12}>
                    <FormGroup>
                        <TextControllerRHF
                            inputName={'promocode'} control={control} errors={errors} defaultValue=''
                            placeholder={t('constructor.pages.promocodePlaceholder')}
                            label={t('constructor.pages.promocode')}
                        />
                    </FormGroup>
                </Col>
                <Col lg={4} md={6} xs={12}>
                    <FormGroup>
                        <Label>{t('constructor.templates.what_bring')}</Label>
                        <ControllerRHF
                            name={'what_bring'}
                            form={form}
                            defaultValue={[]}
                            render={(
                                {value, onChange}
                            ) => {
                                return <AsyncPaginate
                                    value={value?.map(i => ({ value: i, label: i }))}
                                    loadOptions={onLoadDirectory}
                                    isMulti
                                    onChange={(values) => onChange(values?.map(i => i.value) || [])}
                                    debounceTimeout={1000}
                                    closeMenuOnSelect={false}
                                    additional={{ offset: 0, limit: 10, query: "" }}
                                />
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col lg={4} md={6} xs={12}>
                    <FormGroup >
                        <Label>{t('constructor.pages.yaMapsWidget')}</Label>
                        <TextControllerRHF
                            type='textarea'
                            inputName='ya_maps_widget'
                            placeholder={t('constructor.pages.yaMapsWidgetPlaceholder')}
                            control={control}
                            errors={errors}
                        />
                    </FormGroup>
                </Col>
                <Col lg={4} md={6} xs={12}>
                    <FormGroup required>
                        <Label>{t('constructor.pages.event_format')}</Label>
                        <TextControllerRHF
                            type='textarea'
                            inputName='event_format'
                            placeholder={t('constructor.pages.eventFormatPlaceholder')}
                            control={control}
                            errors={errors}
                            isRequired
                            rules={requiredRule}
                        />
                    </FormGroup>
                </Col>
                <Col lg={4} md={6} xs={12}>
                    <FormGroup >
                        <Label>{t('constructor.pages.description')}</Label>
                        <TextControllerRHF
                            type='textarea'
                            inputName='description'
                            placeholder={t('constructor.pages.descriptionPlaceholder')}
                            control={control}
                            errors={errors}
                            isRequired
                            rules={requiredRule}
                        />
                    </FormGroup>
                </Col>
                <Col lg={4} md={6} xs={12}>
                    <FormGroup required>
                        <Label>{t('constructor.pages.duration')}</Label>
                        <TextControllerRHF
                            type='textarea'
                            inputName='duration'
                            isRequired
                            placeholder={t('constructor.pages.durationPlaceholder')}
                            control={control}
                            errors={errors}
                            rules={requiredRule}
                        />
                    </FormGroup>
                </Col>
                <Col lg={4} md={6} xs={12}>
                    <FormGroup required>
                        <Label>{t('constructor.pages.timing')}</Label>
                        <TextControllerRHF
                            type='textarea'
                            inputName='timing'
                            isRequired
                            placeholder={t('constructor.pages.timingPlaceholder')}
                            control={control}
                            errors={errors}
                            rules={requiredRule}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    </TableCard>
})

export default Page