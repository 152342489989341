import React, {useEffect, useState} from "react";
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import PropTypes from "prop-types";
import {useDefaultEndpoints} from "../../../lib/api/useDefaultEndpoints";
import {toast} from "react-toastify";
import {useForm} from "react-hook-form";
import {handleInputChange} from "../../../lib/utils/handleInputChange";
import {t} from "i18next";

export const showDangerToast = e => toast(e, {type: 'error'});
export const showSuccessToast = e => toast(e, {type: 'success'});

const EditModal = (
    {
        entityName, EditForm, entry, triggerModal, isOpened, size = 'md', edit,
        extraButtons = [], onSuccess = undefined, title, onSuccessCb = undefined,
        isFormData = false, isUploadOnlyFile = false,
    }
) => {
    const {createEntry, editEntry} = useDefaultEndpoints(entityName, isFormData, isUploadOnlyFile);
    const [entity, setEntity] = useState({});

    const {handleSubmit, control, errors} = useForm({reValidateMode: 'onBlur',});

    const handleInput = (e, setEntity) => {
        const {name, value} = handleInputChange(e)
        setEntity(entity => ({...entity, [name]: value}))
        return {name, value};
    }

    const createOrUpdate = () => {
        if (entity.id) editEntry(entity.id, entity)
            .then((res) => {
                showSuccessToast(t('common.success'));
                triggerModal();
                onSuccessCb && onSuccessCb(res);
            })
            .catch(showDangerToast)
        else createEntry(entity).then((res) => {
            showSuccessToast(t('common.success'));
            triggerModal();
            onSuccessCb && onSuccessCb(res);
        })
            .catch(showDangerToast);
    };

    const onSubmit = handleSubmit(() => {
        onSuccess ? onSuccess(entity) : createOrUpdate();
    })

    useEffect(() => {
        setEntity(entry || {})
    }, [entry])

    return (
        <Modal isOpen={isOpened} toggle={triggerModal} className={'edit-modal'} size={size}>
            <ModalHeader>{title || (entity.id ? t('generic.editModal.edit') : t('generic.editModal.create'))}</ModalHeader>
            <ModalBody>
                {EditForm({entity, setEntity, control, errors, handleInput})}
            </ModalBody>
            {edit && <ModalFooter>
                <div>
                    <Row>
                        {extraButtons.map((Button, id) => <Col key={id}>{Button({entity, setEntity})}</Col>)}
                    </Row>
                </div>
                <div>
                    <Row>
                        <Col>
                            <Button color="primary" onClick={onSubmit}>
                                {t('generic.okButton')}
                            </Button>
                        </Col>
                        <Col>
                            <Button color="secondary" outline onClick={triggerModal}>
                                {t('generic.cancelButton')}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </ModalFooter>}
        </Modal>
    )
};

EditModal.propTypes = {
    entityName: PropTypes.string.isRequired,
    EditForm: PropTypes.func.isRequired,
    entry: PropTypes.object,
    triggerModal: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    onSuccessCb: PropTypes.func,
    isOpened: PropTypes.bool.isRequired,
    size: PropTypes.oneOf(['lg', '', 'sm']),
    extraButtons: PropTypes.array,
    title: PropTypes.string,
    edit: PropTypes.bool
}

export default EditModal;


