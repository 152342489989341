import React, {useEffect, useMemo, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {useDefaultEndpoints} from "../../../../lib/api/useDefaultEndpoints";
import {Loader} from "../../../generic/loader";
import {TableCard} from "../../../generic/cards";
import { Col, Row} from "reactstrap";
import {FaPencilAlt} from 'react-icons/fa'

import MainInfo from "./MainInfo";
import ArrivalsTable from "./ArrivalsTable";

import {Promocodes} from "../../../bases/events/event/NewEventBlocks";
import {getPermissions} from "../../../../lib/utils/hooks/useRoleSystem";
import { onNewPage, onRoleEntity } from '../helpers';
import ExportByDirectionButton from '../../../common/formElements/ExportByDirectionButton';
import { COMMON_PRODUCT_DIRECTIONS } from '../../../../lib/utils/constants';
import {PRODUCT_TYPES} from "sv-common/constants/certificates";

const AdventureView = ({createTranslatedBreadcrumbsAndTitle}) => {
    let history = useHistory();
    let {url} = useParams();
    const [state, setState] = useState({});
    let entity = useMemo(() => onRoleEntity(state.is_school, state.is_camp, state.is_charter, state.type), [state.is_school, state.type, state.is_camp, state.is_charter]);
    const {getEntries} = useDefaultEndpoints('adventures');
    const [isLoading, setLoading] = useState(true);
    const [typeForRule, setTypeForRule] = useState('');

    useEffect(() => {
        getEntries({url: url}).then(res => {
            const entry = res[0];
            setState(() => entry);
            const mapper = {
                url: {ru: entry.name, en: entry.url},
                type: {ru: entry.type === 'world' ? 'Мир' : 'Россия', en: entry.type}
            }
            createTranslatedBreadcrumbsAndTitle
            && createTranslatedBreadcrumbsAndTitle(mapper, mapper)
            setLoading(() => false);

            if (!entry.is_camp && !entry.is_charter && !entry.is_school) {
                setTypeForRule(PRODUCT_TYPES.ARRIVALS);
            } else if (entry.is_school) {
                setTypeForRule(PRODUCT_TYPES.PRACTICES);
            }
        })// eslint-disable-next-line
    }, [history.location.pathname, url])

    const onEdit = () => {
      const isWithType = !state.is_school;
      onNewPage(state.url, state.is_school, state.is_camp, state.is_charter, true, history, isWithType && state.type, true);
    }

    return isLoading
        ? <Loader/>
        : <TableCard>

            <Row className={'mb-5 justify-content-between align-items-center'}>
                <Col className={'col-sm-auto col-12 flex-grow-1 col mb-3 mb-sm-0'}>
                    <div className={'display-4'}>
                        {state?.name} <FaPencilAlt style={{cursor: 'pointer'}} onClick={onEdit}
                    />
                    </div>
                </Col>
            </Row>

            <Row>
                <Col xs={12} md={6}>
                    <Row>
                        <Col xs={12}>
                            <MainInfo state={state}/>
                        </Col>
                        <Col xs={12}>
                          <ExportByDirectionButton direction={COMMON_PRODUCT_DIRECTIONS.ADVENTURES} directionId={state.id} />
                        </Col>
                        <Col xs={12}>
                            <Promocodes state={state} productType={'adventures'} disabled={!getPermissions(entity).edit}/>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} md={6}>
                     <ArrivalsTable state={state} typeForRule={typeForRule} />
                </Col>

            </Row>
        </TableCard>
}


export default AdventureView;